"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var defaultAppData = {
  "settings": {
    "basic_settings": {
      "name": {
        "lang_1": "Boligkonfigurator"
      },
      "short_name": {
        "lang_1": ""
      },
      "description": {
        "lang_1": "Beskrivelse, lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
      },
      "logo": {
        "disable": true,
        "svg": ""
      },
      "home_logo_icon": {
        "disable": true,
        "svg": ""
      },
      "home_logo_text": {
        "disable": true,
        "svg": ""
      },
      "footer_logo": {
        "disable": true,
        "svg": ""
      },
      "footer_copyright": {
        "lang_1": ""
      },
      "languages": ["lang_1"],
      "currency": {
        "lang_1": "NOK"
      },
      "currency_value": {
        "lang_1": 1
      }
    },
    "project_settings": {
      "project_address": {
        "lang_1": "Norway, Oslo, Street street 7."
      },
      "project_contact_person": {
        "lang_1": "Thomas Malmo"
      },
      "project_contact_phone": {
        "lang_1": "+47901324567"
      },
      "project_contact_email": {
        "lang_1": "thomas@lightroomstudio.no"
      },
      "gallery": [{
        "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/project_gallery_0.jpg"
      }, {
        "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/project_gallery_1.jpg"
      }]
    },
    "models_settings": {
      "models_name": {
        "lang_1": "Velg model på leiligheten"
      },
      "models_description": {
        "lang_1": "Beskrivelse av models, lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      }
    },
    "configurations_settings": {
      "configurations_name": {
        "lang_1": "Velg startsett med eiendommer for leiligheten"
      },
      "configurations_description": {
        "lang_1": "Om samlinger generelt, lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      }
    },
    "summary_settings": {
      "summary_name": {
        "lang_1": "Godt valg! Her er detaljene i leiligheten din"
      },
      "summary_description": {
        "lang_1": "Sammendrag sidebeskrivelse, lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      },
      "summary_founding_name": {
        "lang_1": "Finansieringsmuligheter"
      },
      "summary_founding_description": {
        "lang_1": "Beskrivelse av finansieringsmuligheter, lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      },
      "summary_contact_name": {
        "lang_1": "Kontakt oss"
      },
      "summary_save_options_name": {
        "lang_1": "Lagre"
      }
    },
    "context": {
      "menus": {
        "startConfiguratorMenu": {
          "lang_1": "Start boligkonfigurator"
        },
        "descriptionMenu": {
          "lang_1": "Beskrivelse"
        },
        "modelsMenu": {
          "lang_1": "Model"
        },
        "collectionsMenu": {
          "lang_1": "Samling"
        },
        "summaryMenu": {
          "lang_1": "Sammendrag"
        },
        "collectionsMenuCustomSpan": {
          "lang_1": "Tilpasset"
        }
      },
      "messages": {
        "notFoundModel": {
          "lang_1": "Det er ingen models i denne konfiguratoren"
        },
        "notFoundCollection": {
          "lang_1": "Det er ingen samlinger for denne model"
        },
        "notFoundAnswer": {
          "lang_1": "Det er ikke muligheter for dette spørsmålet"
        }
      },
      "labels": {
        "nextLabel": {
          "lang_1": "Neste"
        },
        "prevLabel": {
          "lang_1": "Tidligere"
        },
        "summaryLabel": {
          "lang_1": "Sammendrag"
        },
        "modelsLabel": {
          "lang_1": "Models"
        },
        "collectionsLabel": {
          "lang_1": "Samlinger"
        },
        "copyUrlToClipboardLabel": {
          "lang_1": "Kopier url til utklippstavlen"
        },
        "printLabel": {
          "lang_1": "Skrive ut"
        },
        "selectLabel": {
          "lang_1": "Velge"
        },
        "selectedLabel": {
          "lang_1": "Valgt"
        },
        "languages": {
          "lang_1": "Norsk"
        }
      }
    }
  },
  "models": [{
    "key": "model_1",
    "name": {
      "lang_1": "Model 1"
    },
    "description": {
      "lang_1": "Minimal stil, lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
    },
    "content": {
      "lang_1": "Lang tekst lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Long text lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    "price": {
      "lang_1": 69000000
    },
    "gallery": [{
      "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_gallery_0.jpg"
    }, {
      "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_gallery_1.jpg"
    }, {
      "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_gallery_2.jpg"
    }, {
      "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/floorplan.jpg"
    }],
    "gallery_index_for_summary": 3,
    "collections": [{
      "key": "default",
      "name": {
        "lang_1": " Standard"
      },
      "description": {
        "lang_1": "Dette er grunnpakken, lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
      }
    }],
    "steps": [{
      "key": "technical-equipment",
      "name": {
        "lang_1": "Teknisk utstyr"
      },
      "description": {
        "lang_1": "Velg teknisk utstyr i leiligheten din"
      },
      "options": [{
        "key": "heating-system",
        "name": {
          "lang_1": "Varmesystem"
        },
        "description": {
          "lang_1": "Velg type varmesystem"
        },
        "options": [{
          "key": "electronic",
          "name": {
            "lang_1": "Elektronisk"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 0
          },
          "collections": ["default"],
          "gallery": [],
          "layer": []
        }, {
          "key": "gas",
          "name": {
            "lang_1": "Gass"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 850000
          },
          "collections": [],
          "gallery": [],
          "layer": []
        }, {
          "key": "heat-pump",
          "name": {
            "lang_1": "Varmepumpe"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 2850000
          },
          "collections": [],
          "gallery": [],
          "layer": []
        }]
      }, {
        "key": "heating-surface",
        "name": {
          "lang_1": "Oppvarmingsflate"
        },
        "description": {
          "lang_1": "Velg type varmeoverflate"
        },
        "options": [{
          "key": "radiator",
          "name": {
            "lang_1": "Radiator"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 0
          },
          "collections": ["default"],
          "gallery": [],
          "layer": []
        }, {
          "key": "floor",
          "name": {
            "lang_1": "Gulv"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 1750000
          },
          "collections": [],
          "gallery": [],
          "layer": []
        }, {
          "key": "floor-wall",
          "name": {
            "lang_1": "Gulv-vegg"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 3525000
          },
          "collections": [],
          "gallery": [],
          "layer": []
        }, {
          "key": "floor-ceiling",
          "name": {
            "lang_1": "Gulv-tak"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 3525000
          },
          "collections": [],
          "gallery": [],
          "layer": []
        }]
      }, {
        "key": "cooling",
        "name": {
          "lang_1": "Kjøling"
        },
        "description": {
          "lang_1": "Velg type kjølesystem"
        },
        "options": [{
          "key": "air-conditioner",
          "name": {
            "lang_1": "Klimaanlegg"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 0
          },
          "collections": ["default"],
          "gallery": [],
          "layer": []
        }, {
          "key": "surface-cooling",
          "name": {
            "lang_1": "Overflatekjøling"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 2550000
          },
          "collections": [],
          "gallery": [],
          "layer": []
        }]
      }, {
        "key": "electronic-system",
        "name": {
          "lang_1": "Elektronisk system"
        },
        "description": {
          "lang_1": "Velg typen elektronisk system"
        },
        "options": [{
          "key": "not-smart",
          "name": {
            "lang_1": "Ikke smart"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 0
          },
          "collections": ["default"],
          "gallery": [],
          "layer": []
        }, {
          "key": "smart-kameleon",
          "name": {
            "lang_1": "Smart - 1"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 2550000
          },
          "collections": [],
          "gallery": [],
          "layer": []
        }, {
          "key": "smart-loxone",
          "name": {
            "lang_1": "Smart - Loxone"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 5100000
          },
          "collections": [],
          "gallery": [],
          "layer": []
        }]
      }],
      "layer": []
    }, {
      "key": "kitchen",
      "name": {
        "lang_1": "Kjøkken"
      },
      "description": {
        "lang_1": "Velg teknisk utstyr og design på kjøkkenet ditt"
      },
      "layer": [{
        "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen.jpg",
        "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen.jpg?night"
      }, {
        "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen.jpg",
        "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen.jpg?night"
      }],
      "options": [{
        "key": "pavement",
        "name": {
          "lang_1": "Fortau"
        },
        "description": {
          "lang_1": "Velg fortauet til kjøkkenet ditt"
        },
        "options": [{
          "key": "pvc-laminate",
          "name": {
            "lang_1": "PVC - laminat"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 0
          },
          "collections": ["default"],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_0.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_0.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_0.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_0.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_green.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }, {
          "key": "carpet",
          "name": {
            "lang_1": "Teppe"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 150000
          },
          "collections": [],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_1.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_1.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_1.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_1.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_pink.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }, {
          "key": "tile",
          "name": {
            "lang_1": "Fliser"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 600000
          },
          "collections": [],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_2.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_2.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_2.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_2.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_orange.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }, {
          "key": "floor",
          "name": {
            "lang_1": "Gulv"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 1200000
          },
          "collections": [],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_3.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_3.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_3.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_pavement_3.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_blue.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }]
      }, {
        "key": "furniture-color",
        "name": {
          "lang_1": "Møbler farge"
        },
        "description": {
          "lang_1": "Velg fargen på kjøkkenmøblene dine"
        },
        "options": [{
          "key": "laminate",
          "name": {
            "lang_1": "Laminat"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 0
          },
          "collections": ["default"],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_0.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_0.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_0.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_0.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_green.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }, {
          "key": "veneered-front",
          "name": {
            "lang_1": "Finert front"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 0
          },
          "collections": [],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_1.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_1.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_1.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_1.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_pink.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }, {
          "key": "painted-matt",
          "name": {
            "lang_1": "Malt matt"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 350000
          },
          "collections": [],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_2.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_2.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_2.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_2.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_orange.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }, {
          "key": "painted-brightly",
          "name": {
            "lang_1": "Malt sterkt"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 450000
          },
          "collections": [],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_3.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_3.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_3.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_3.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_blue.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }, {
          "key": "glass",
          "name": {
            "lang_1": "Glass"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 650000
          },
          "collections": [],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_4.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_4.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_4.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_4.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_yellow.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }, {
          "key": "stone",
          "name": {
            "lang_1": "Stein"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 950000
          },
          "collections": [],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_5.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_5.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_5.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_furniture_color_5.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_red.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }]
      }, {
        "key": "worktop",
        "name": {
          "lang_1": "Benkeplate"
        },
        "description": {
          "lang_1": "Velg tyoen på kjøkkenbenken"
        },
        "options": [{
          "key": "laminate",
          "name": {
            "lang_1": "Laminat"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 0
          },
          "collections": ["default"],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_0.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_0.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_0.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_0.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_green.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }, {
          "key": "artifical-stone",
          "name": {
            "lang_1": "Kunstig stein"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 300000
          },
          "collections": [],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_1.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_1.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_1.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_1.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_pink.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }, {
          "key": "marble",
          "name": {
            "lang_1": "Marmor"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 600000
          },
          "collections": [],
          "layer": [{
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_2.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_2.png?night"
          }, {
            "image_url_day": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_2.png",
            "image_url_night": "https://rematech.carco.hu/demo/boligkonfigurator-app/minimal_kitchen_worktop_2.png?night"
          }],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_orange.jpg"
          }, {
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }]
        }]
      }, {
        "key": "sink-tray",
        "name": {
          "lang_1": "Vaskebrett"
        },
        "description": {
          "lang_1": "Velg typen vaskebrett"
        },
        "options": [{
          "key": "worktop-material",
          "name": {
            "lang_1": "Benkeplatemateriale"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 0
          },
          "collections": ["default"],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }],
          "layer": []
        }, {
          "key": "individually-designed",
          "name": {
            "lang_1": "Individuelt designet"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 280000
          },
          "collections": [],
          "gallery": [{
            "image_url": "https://rematech.carco.hu/demo/boligkonfigurator-app/thumb_black.jpg"
          }],
          "layer": []
        }]
      }, {
        "key": "kitchen-pig",
        "name": {
          "lang_1": "Kjøkkengris"
        },
        "description": {
          "lang_1": "Vil du ha et kjøkkengris på kjøkkenet ditt?"
        },
        "options": [{
          "key": "no",
          "name": {
            "lang_1": "Nei, det gjør jeg ikke"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 0
          },
          "collections": ["default"],
          "gallery": [],
          "layer": []
        }, {
          "key": "yes",
          "name": {
            "lang_1": "Ja jeg vil ha det"
          },
          "description": {
            "lang_1": "Norsk tekst, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
          },
          "price": {
            "lang_1": 280000
          },
          "collections": [],
          "gallery": [],
          "layer": []
        }]
      }]
    }]
  }]
};
var _default = defaultAppData;
exports["default"] = _default;